import GLightbox from "glightbox";
import { tns } from "tiny-slider/src/tiny-slider";

document.addEventListener("DOMContentLoaded", function (event) {

    //Mobile Menu Button
    const menu_button = document.querySelector('[data-behaviour="toggle-menu"]');

    menu_button.addEventListener('click', () => {
        document.body.classList.toggle('body--show');
    })

    //Lightbox
    const lightbox = GLightbox({
        selector: '[data-gallery="gallery"]',
        touchNavigation: true,
        loop: true,
    });

    //Toggle Multiselect
    const multiselect = document.querySelectorAll('[data-behaviour="toggle-multiselect"]');
    multiselect.forEach((element) => {
        element.addEventListener('click', (e) => {
            e.preventDefault();
            element.nextElementSibling.classList.toggle('active');
            if( element.nextElementSibling.classList.contains('active') ) {
                element.nextElementSibling.style.maxHeight = element.nextElementSibling.scrollHeight + "px";
            } else {
                element.nextElementSibling.style.maxHeight = 0;
            }
        })
    });

    //Show hide
    let showHideElements = [...document.querySelectorAll('[data-behaviour="showhide"]')];

    showHideElements.forEach((element) => {
        element.addEventListener("click", (e) => {
            e.preventDefault();
            element.parentNode.classList.toggle("showhide--selected")

            showHideElements.filter(e => e != element).forEach((e) => e.parentNode.classList.remove("showhide--selected"));
            setTimeout(() => {
                element.scrollIntoView({
                    behavior: 'smooth',
                });
            }, 500);
        })
    });

    let showHideCourseElements = [...document.querySelectorAll('[data-behaviour="course-showhide"]')];

    showHideCourseElements.forEach((element) => {
        element.addEventListener("click", (e) => {
            e.preventDefault();
            element.parentNode.parentNode.parentNode.classList.toggle("course-showhide--selected")

            showHideCourseElements.filter(e => e != element).forEach((e) => e.parentNode.classList.remove("course-showhide--selected"));
            setTimeout(() => {
                element.scrollIntoView({
                    behavior: 'smooth',
                });
            }, 500);
        })
    });

    //Slider
    var sliders = document.querySelectorAll('[data-behaviour="slider"]');

    if (sliders.length) {
        [...sliders].map(slider => {
                return tns({
                    mode: 'gallery',
                    container: slider,
                    items: 1,
                    slideBy: 'page',
                    navAsThumbnails: true,
                    nav: false,
                    controls: true,
                    controlsText: ['&lt;', '&gt;'],
                    mouseDrag: true,
                    autoplay: true,
                    autoplayTimeout: 3000,
                    speed: 2000,
                    autoplayHoverPause: true,
                    autoplayButtonOutput: false,
                });
        })
    }

    const team_items = document.querySelectorAll('[data-behaviour="team-item"]');

    team_items.forEach((element) => {
        element.addEventListener('click', (e) => {
            e.preventDefault();
            // var item = element;
            // item.nextElementSibling.classList.toggle("team_item--visible");
            //
            // team_items.filter(e => e != item).forEach((e) => {
            //     e.nextElementSibling.classList.remove("team_item--visible")
            // });

            const oldDetails = document.querySelector('.team_list > .team_item_description')
            if (oldDetails) {
                oldDetails.remove();
            }

            if (element.classList.contains('team_item_wrap--selected')) {
                element.classList.remove('team_item_wrap--selected');
            } else {
                const myOffset = element.offsetTop;
                console.log(myOffset);
                let insertionPoint = element;
                team_items.forEach(item => {
                    item.classList.remove('team_item_wrap--selected');
                    if (item.offsetTop === myOffset) {
                        insertionPoint = item;
                    }
                })
                console.log(insertionPoint);
                element.classList.add('team_item_wrap--selected')

                const newDetails = element.querySelector('.team_item_description').cloneNode(true);
                insertionPoint.insertAdjacentElement('afterend', newDetails);
                setTimeout(() => {
                    newDetails.classList.add('team_item--visible');
                }, 0)
            }

            // $(".inserted_details").hide().remove();
            //
            // //Search correct insertion point: Last with same offset
            // var $insertionpoint;
            // var myoffset = $(this).offset().top;
            // $(".gallery_item", $(this).parent()).each(function() {
            //     if($(this).offset().top == myoffset)
            //         $insertionpoint = $(this);
            // });
            //
            // if($(this).hasClass("gallery--active")) {
            //     $(".gallery--activeitem").removeClass("gallery--activeitem");
            //     $(".gallery--active").removeClass("gallery--active");
            // } else {
            //     $(".gallery--activeitem").removeClass("gallery--activeitem");
            //     $(".gallery--active").removeClass("gallery--active");
            //     $(this).parent().addClass("gallery--activeitem");
            //     $(this).addClass("gallery--active");
            //
            //     //Insert details and show
            //     $(".gallery_details", $(this)).clone().insertAfter($insertionpoint).addClass("inserted_details").slideDown();
            // }
            // console.log($insertionpoint);
        });
    })
});
